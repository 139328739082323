

























import { Component, Vue } from 'vue-property-decorator'
import SelectBox from '@/components/SelectBox.vue'
import { SprayState } from '@/store/modules/walkthrough/types/pre-test/sprayState'
import Modal from '@/components/common/Modal.vue'

@Component({
  components: {
    SelectBox,
    Modal
  }
})
export default class Step1 extends Vue {
  $refs!: Vue['$refs'] & { selectBox: SelectBox }
  private selected = ''
  private isModal = false

  private updateVal (ev: string): void {
    if (ev === 'Yes') this.isModal = true
    this.selected = ev
    this.$store.dispatch('walkthrough/setSprayState', this.selected === 'Yes' ? SprayState.YesUsed : SprayState.NotUsed)
  }

  private mounted (): void {
    const previouslySelectedValue = this.$store.getters['walkthrough/sprayState']
    const selectedItem = previouslySelectedValue === 1 ? 'Yes' : previouslySelectedValue === 0 ? 'No' : ''
    if (previouslySelectedValue === '') {
      return
    }
    (this.$refs.selectBox as SelectBox).setVal(selectedItem)
    this.selected = selectedItem
  }

  private goTo (): void {
    this.$router.push('2')
  }

  private closeModal (): void {
    this.isModal = false
    this.selected = ''
    this.$refs.selectBox.setVal(this.selected)
  }
}
